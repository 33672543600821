import React, { useState, useEffect } from "react"
import ReactCardFlip from "react-card-flip"
import styled from "styled-components"

import screen1 from "../assets/screen1.svg"
import screen2 from "../assets/screen2.svg"

const items = [
  {
    name: "Cristen",
    title: "Mother",
    quote:
      "Cloud Castles was exactly the investment vehicle I had been looking for. It makes real estate buying fun and accessible, without any of the pain or high commitment levels. ",
    img:
      "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/11233d7d3bbb4d3ebc7aefb14d3316e4/jimmy.png",
    name2: "Patti M",
    titl2: "Overtime Mom",
    quote2:
      "I'm so excited to have my own slice of Tahoe!  And it is making me money!",
    img2: "https://st.hzcdn.com/simgs/fb7313c50304f610_3-4065/_.jpg",
    name3: "Nick P.",
    title3: "Finance",
    quote3:
      "I cannot believe how easy it was to make a real estate investment with Cloud Castles. Bing-bang-boom I have equity in a house. ",
    img3:
      "https://media-exp1.licdn.com/dms/image/C5603AQF3uNjuXlg9_A/profile-displayphoto-shrink_400_400/0/1628798346281?e=1637193600&v=beta&t=5rg6Rn1xIE3JZAz3DReceGtO_CL74KQrKKQs6Zc50aU",

    name4: "Rachel",
    titl4: "Marketer",
    quote4:
      "I love how easy it is to stay there and make money at the same time!  I can own a little bit everywhere!",
    img4:
      "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/11233d7d3bbb4d3ebc7aefb14d3316e4/userphotos/rachel.jpeg",
  },
  {
    name: "Sergio",
    title: "Investor",
    quote:
      "Banks give you nothing nowadays so it made sense to get a higher return on money just sitting there anyway. Oh, and the monthly payouts makes it even better!",
    img:
      "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/11233d7d3bbb4d3ebc7aefb14d3316e4/userphotos/sergio.jpeg",
    name2: "Patti M",
    titl2: "Overtime Mom",
    quote2:
      "I'm so excited to have my own slice of Tahoe!  And it is making me money!",
    img2: "https://st.hzcdn.com/simgs/fb7313c50304f610_3-4065/_.jpg",
    name3: "Nick P.",
    title3: "Finace",
    quote3:
      "I cannot believe how easy it was to make a real estate investment with Cloud Castles. Bing-bang-boom I have equity in a house. ",
    img3:
      "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/11233d7d3bbb4d3ebc7aefb14d3316e4/userphotos/nick.jpeg",

    name4: "Jimmy",
    titl4: "New Father",
    quote4:
      "Also easy to see how my investment is doing and see my returns. Could not have had a better experience!",
    img4:
      "https://media-exp1.licdn.com/dms/image/C4D03AQHUmWsjYyQALw/profile-displayphoto-shrink_400_400/0/1516923819013?e=1637193600&v=beta&t=QgeTkfwzjSR8iL3Kyc1JnUC7t7YddfNGWLPLRiF1csE",
  },
]

const TestiCards = () => {
  const [mediaItem, setMediaItem] = React.useState(items[0]) // <-- seed initial state
  const [index, setIndex] = React.useState(0)
  const [isFlipped, setIsFlipped] = React.useState(false)
  const [animate, setAnimate] = React.useState(false)

  const handleClick = () => {
    setIsFlipped(!isFlipped)
  }

  useEffect(() => {
    const timerId = setInterval(
      () => setIndex(i => (i + 1) % items.length), // <-- increment index
      12000
    )

    return () => clearInterval(timerId)
  }, [])

  useEffect(() => {
    const timerId2 = setInterval(
      () => setAnimate(!animate), // <-- increment index
      6000
    )

    return () => clearInterval(timerId2)
  }, [])

  useEffect(() => {
    const timerId3 = setInterval(
      () => handleClick(), // <-- Flip
      6000
    )

    return () => clearInterval(timerId3)
  }, [isFlipped])

  useEffect(() => {
    setMediaItem(items[index]) // <-- update media state when index updates
  }, [index])

  return (
    <>
      <Testi>TESTIMONIALS</Testi>
      <Testim>TESTIMONIALS</Testim>
      <Hero>
      <Hero1>
        <Screen1 alt="screen1" src={screen1} />
        <Screen2 alt="screen2" src={screen2} />
      </Hero1>
      <Hero2>
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection="vertical"
          infinite={true}
        >
        
          <Card1>
            <UserImg src={mediaItem.img} />
            <UserName>{mediaItem.name}</UserName>
            <UserDeets>{mediaItem.title}</UserDeets>
            <Text>{mediaItem.quote}</Text>
          </Card1>
          <Card2>
            <UserImg src={mediaItem.img2} />
            <UserName>{mediaItem.name2}</UserName>
            <UserDeets>{mediaItem.titl2}</UserDeets>
            <Text>{mediaItem.quote2}</Text>
          </Card2>
        </ReactCardFlip>
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection="vertical"
          infinite={true}
        >
          <Card2>
            <UserImg src={mediaItem.img4} />
            <UserName>{mediaItem.name4}</UserName>
            <UserDeets>{mediaItem.titl4}</UserDeets>
            <Text>{mediaItem.quote4}</Text>
          </Card2>
          <Card1>
            <UserImg src={mediaItem.img3} />
            <UserName>{mediaItem.name3}</UserName>
            <UserDeets>{mediaItem.title3}</UserDeets>
            <Text>{mediaItem.quote3}</Text>
          </Card1>
        </ReactCardFlip>
        </Hero2>
        </Hero>
        <HowText1>$10,000+ Paid Out To Users Every Month</HowText1>
    </>
  )
}
export default TestiCards

const Body = styled.div`
  margin:0px;
  max-width: 100%;
  height:100%;
  overflow-x: hidden;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 10px auto;

  // padding-top:  100px;
  // padding-bottom:  100px;

  @media only screen and (max-width: 800px) and (min-width: 501px) {
    flex-direction: column-reverse;
    // margin: -70px 50px 50px -20px;
  }
  @media only screen and (max-width: 500px) and (min-width: 100px) {
    flex-direction: column-reverse;
    // margin: -70px 50px 50px -20px;
    max-width: 100%;
  }

`

const Hero = styled.div`
  // background:#8bbef3;
  margin:0px;
  max-width: 100%;
  height:100%;
  overflow-x: hidden;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 10px auto;
  @media only screen and (max-width: 800px) and (min-width: 501px) {
    flex-direction: column-reverse;
    // margin: -70px 50px 50px -20px;
  }
  @media only screen and (max-width: 500px) and (min-width: 100px) {
    flex-direction: column-reverse;
    // margin: -70px 50px 50px -20px;
    max-width: 100%;
  }
`
const Hero1 = styled.div`  
  display: flex;
  flex-direction:row;
  // justify-content: center;
  // align-content: center;
  margin: auto;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  width:45%;
  // max-height:100%;
  @media only screen and (max-width:800px) and (min-width: 100px) {
    // margin: 100px auto 50px 100px;
    width:80%;
  }  

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    flex-direction:column;
  }

`

const Hero2 = styled.div`
  
  display: flex;
  width:50%;
// margin: 100px auto 0px 0px;
// padding 100px auto auto 100px;
  flex-direction:column;
  // justify-content: center;
  // align-content: center;
  margin: auto;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  width:45%;
  max-height:100%;
  @media only screen and (max-width:800px) and (min-width: 100px) {
    // margin: 100px auto 50px 100px;
    width:80%;
  }  

 
`

const Testi = styled.h2`
  position: relative;
  width: 100%;
  height: 99px;
  // left: 320.32px;
  // top: 40.21px;

    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 65.7269px;
  line-height: 99px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;

  opacity: 0.04;
  @media only screen and (max-width: 1200px) and (min-width: 801px) {
  }
  @media only screen and (max-width: 800px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 45.7269px;

  }
`

const Testim = styled.h2`
  position: relative;
  width: 100%;
  height: 99px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // left: 320.32px;
  top: -25.21px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36.9714px;
  line-height: 55px;
  text-transform: uppercase;

  color: #000000;
  @media only screen and (max-width: 1200px) and (min-width: 801px) {
  }
  @media only screen and (max-width: 800px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    
  }
`

const Screen1 = styled.img`
  position: relative;
  width: 338.46px;
  height: 668.07px;
  // left: 800.58px;
  // top: 54.65px;
    @media only screen and (max-width: 1200px) and (min-width: 801px) {
  }
  @media only screen and (max-width: 800px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    margin-top:30px;
    height:520px;
  }
`
const Screen2 = styled.img`
  position: relative;
  width: 338.46px;
  height: 668.07px;
  // left: 960.58px;
  // top: 104.65px;
    @media only screen and (max-width: 1200px) and (min-width: 801px) {
  }
  @media only screen and (max-width: 800px) and (min-width: 426px) {
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    height:520px;
  }
`

const Card1 = styled.div`
  background: white;
  position: relative;
  width: 90%;
  height: 291px;
  margin-left: 50.58px;
  margin-top: 50.58px;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 1200px) and (min-width: 801px) {
    margin-left: 30.58px; 
  }
  @media only screen and (max-width: 800px) and (min-width: 426px) {
    margin-left: 30.58px; 
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // width: 300px;
    margin-left: 10.58px; 
    // height: 291px;
    // left: 10.58px;
    // top: 74.65px;
    // right: 10%;
  }
`

const Card2 = styled.div`
  background: white;
  position: relative;
  width: 90%;
  height: 291px;
  margin-left: 50.58px;
  margin-top: 20.58px;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 1200px) and (min-width: 801px) {
    margin-left: 30.58px; 
  }
  @media only screen and (max-width: 800px) and (min-width: 426px) {
   margin-left: 30.58px; 
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    margin-left: 10.58px; 
  }
`
const Text = styled.p`
  position: absolute;

  height: 112.75px;
  left: 5%;
  right: 5%;
  bottom: 20.71px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;
  /* or 199% */

  color: #5a7184;
  @media only screen and (max-width: 800px) and (min-width: 426px) {
    font-size: 17px;
    line-height: 16px;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 14px;
    line-height: 16px;
  }
`

const UserImg = styled.img`
  position: absolute;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  left: 50px;
  right: 0px;
  top: 30px;
  bottom: 0px;

  background: url(Image.png);
  @media only screen and (max-width: 1200px) and (min-width: 801px) {
    left: 22.58px; 
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
   left: 20px;
  }
`

const UserName = styled.h2`
  position: absolute;
  height: 36px;
  left: 30.03%;
  right: 13.12%;
  top: calc(25% - 36px / 2 - 18px);

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 36px;
  /* identical to box height, or 143% */
  color: #183b56;
  @media only screen and (max-width: 800px) and (min-width: 426px) {
    left: 56.03%;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    left: 46.03%;
    right: 4.12%;
  }
`

const UserDeets = styled.h3`
  position: absolute;
  height: 31.36px;
  left: 30.03%;
  right: 0%;
  top: calc(25% - 31.36px / 2 + 21.12px);

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 17.9176px;
  line-height: 31px;
  /* identical to box height, or 175% */

  color: #5a7184;
  @media only screen and (max-width: 800px) and (min-width: 426px) {
    left: 56.03%;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    left: 46.03%;
    right: 4.12%;
  }
`

const HowText1 = styled.h2`
  position: absolute;
  height: 34px;
  left: 16.12%;
  right: 5.18%;
  bottom: 100px;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24.6476px;
  line-height: 33px;

  /* or 134% */
  color: #1d293f;

    @media only screen and (max-width: 425px) and (min-width: 100px) {
    left: 2.03%;
    right: 4.12%;
    font-size: 15.9176px;
  line-height: 20px;
  }
`
