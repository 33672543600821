import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./header.css"

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasScrolled: false,
    }

    // this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 50) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  render() {
    return (
      <div
        className={this.state.hasScrolled ? "Header HeaderScrolled" : "Header"}
      >
        <div className="HeaderGroup">
          <img
            src={
              this.state.hasScrolled
                ? "https://cloudcastles.io/wp-content/uploads/2020/11/black_logo_text.png"
                : "https://cloudcastles.io/wp-content/uploads/2020/11/white_logo_text.png"
            }
            alt="Logo"
          />
          <Link to="/">Blog</Link>
          <Link to="/">Blog</Link>
          <Link to="/">
            <button>Buy</button>
          </Link>
          <Link to="/">
            <button>Sell</button>
          </Link>
        </div>
      </div>
    )
  }
}

export default Header
