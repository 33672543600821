import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Signup = () => {
  const [email, setEmail] = React.useState("")
  const [emailError, setEmailError] = React.useState("")
  const [emailSent, setEmailSent] = React.useState("")

  const handleSumit = event => {
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault()

    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    console.log("FUCK YOU")

        if (re.test(email)) {
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.

      var axios = require("axios")
      var data = JSON.stringify({
        email: email,
        dataFields: { source: "index_footer_web" },
      })

      // frontend iterable
      var config = {
        method: "post",
        url: "https://api.iterable.com/api/users/update",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "dd373517425447ca8ab5d993282d17ab",
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
          setEmailSent("We'll send you info!")
        })
        .catch(function (error) {
          console.log(error)
        })

      console.log(email)
      setEmailError("")
      setEmailSent("We'll send you info!")
    } else {
      // invalid email, maybe show an error to the user.
      console.log(email)
      setEmailSent("")
      setEmailError("Please set a valid email")
    }
  }

  return (
    <Body>
      <Title>Not quite ready?</Title>
      <DTitle>Join our email list to learn more about investing</DTitle>
      <form
        style={{
          display: "grid",
          jusityitems: "center",
          alignitems: "center",
        }}
        onSubmit={handleSumit}
      >
        <Input
          type="text"
          placeholder="email"
          error={emailError}
          onChange={event => setEmail(event.target.value)}
        />
        <Button type="submit">Sign Up</Button>

        {/* <Button as="a" href="/"> */}
        {/*   Signup */}
        {/* </Button> */}
      </form>
                    <span style={{ color: "red" }}>{emailError}</span>
              <span style={{ color: "black" }}>{emailSent}</span>
    </Body>
  )
}
export default Signup

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  padding:20px;
  width: 800px;
  height: 250px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    height: 300px;
  }
`
const Title = styled.h2`
  width: 50%;
  height: 160px;
  left: 544px;
  top: 3533.05px;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height */

  text-align: center;

  color: #161930;

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 20px;
    // line-height: 60px;
  }
`

const DTitle = styled.h2`
  width: 50%;
  height: 160px;
  left: 544px;
  top: 3533.05px;

  font-family: Poppins;
  font-style: 10px;
  font-weight: 130px;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #161930;

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    font-size: 14px;
    // line-height: 60px;
  }
`


const Input = styled.input`
  width: 100%;
  padding: 0.5em;
  margin: 0.5em;
  background: white;
  border: none;
  border-radius: 3px;
  background: #ffffff;
  border: 2px solid #4976ff;
  box-sizing: border-box;
  box-shadow: 0px 8px 36px rgba(73, 118, 255, 0.2);
  border-radius: 11px;
`

const Button = styled.button`
  width: 100%;
  display: inline-block;
  background: linear-gradient(0deg, #161930, #161930), #ffbc3a;
  box-shadow: 0px 12.6861px 45px rgba(22, 25, 48, 0.19);
  font-size: 0.8em;
  margin: 1em;
  padding: 0.3em 3em;

  border-radius: 11px;
  display: block;

  font-family: Poppins;
  font-style: normal;

  color: #ffffff;
`
